import dayjs from 'dayjs'
import Calendar from 'dayjs/plugin/calendar'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import LocaleData from 'dayjs/plugin/localeData'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import { useLocalization } from '#imports'

import type { DateInterval } from '@winestyle/api-client/src/ts/api/shared/v1/common_pb.js'

dayjs.extend(Calendar)
dayjs.extend(UpdateLocale)
dayjs.extend(LocaleData)
dayjs.updateLocale(
  'ru',
  { monthsShort: ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'] }
)

type UseDays = {
  dateToCalendar: (date: string | undefined, short?: boolean, withYear?: boolean) => string | null
  getAvailabilityDate: (dateIso: string, interval?: DateInterval.AsObject | undefined, short?: boolean) => string | null
}

export const useDays = (): UseDays => {
  const { locale, t } = useLocalization()

  if (dayjs.locale() !== locale.value) {
    dayjs.locale(locale.value)
  }

  const dateToCalendar: UseDays['dateToCalendar'] = (date, short = false, withYear = false) => {
    const monthNum = date?.split?.('-')?.[1]
    const shortMonthIdxList = ['03', '05', '06', '07']

    const dateFormat = `D ${!short || shortMonthIdxList.includes(monthNum ?? '') ? 'MMMM' : 'MMM'}${withYear ? ' YYYY' : ''}`
    return date
      ? dayjs(date.slice(0, 10)).calendar(null, {
        sameDay: `[${t('atoms.badge-getting.today')}]`,
        nextDay: `[${t('atoms.badge-getting.tomorrow')}]`,
        nextWeek: dateFormat,
        lastDay: `[${t('atoms.badge-getting.yesterday')}]`,
        lastWeek: dateFormat,
        sameElse: dateFormat
      })
      : null
  }

  const getAvailabilityDate: UseDays['getAvailabilityDate'] = (dateIso, interval = undefined, short = false) => {
    if (interval?.dateFromIso) {
      let from = dateToCalendar(interval?.dateFromIso, short) as string
      const to = dateToCalendar(interval?.dateToIso, short) as string
      const [fromMonth, toMonth] = [from.split(' ')[1], to.split(' ')[1]]
      const inOneMonth = fromMonth !== '' && fromMonth === toMonth

      from = inOneMonth ? from.replace(new RegExp(fromMonth, 'g'), '') : from
      return `${from} ${interval?.dateToIso ? '- ' + to : ''}`
    }

    return dateToCalendar(dateIso, short)
  }

  return { dateToCalendar, getAvailabilityDate }
}
